import { defineComponent, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import './index.less'

export default defineComponent({
    setup () {
        const router = useRouter()

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>注销协议</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class="agree-info-box logoff">
                    <ul>
                        <li>
                            <div class="logoHeader">【重要】</div>
                            <div class="pb60">
                                翻腕平台账号系由上海往矣科技有限公司（以下简称“我们”）就您使用部分或全部翻腕平台服务 （包括但不限于翻腕
                                商家招聘端、翻腕求职端等）的通用登录账号。在您决定申请账号注销前，请根据需要慎重选择单独注销翻腕商家招聘端
                                或一并注销翻腕平台账号。申请“仅注销翻腕商家招聘端账号”的，您将无法使用翻腕提供的商家招聘产品与服务
                                ；申请“注销翻腕平台账号”的，您将无法使用翻腕平台提供的全部产品与服务（翻腕商家招聘端、翻腕求职端）。
                            </div>
                            <div class="pb60">
                                需要特别说明的是，下文中“账号”特指您选择申请注销的账号类型，“产品与服务”特指与您申请注销账号相应的我 们的产品与服务，本《注销协议》相关条款的解释，将根据您实际选择注销账号的类型确定具体含义。
                            </div>
                        </li>
                        <li>
                            <div class="logoHeader">【特别提示】</div>
                            <div class="pb60">
                                当您按照注销页面提示填写信息、阅读并同意本《注销协议》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销协议》的全部内容。阅读本《注销协议》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账号注销程序。
                            </div>
                            <div class="pb60">
                                账号注销意味着我们将在系统中删除您的个人信息，使其保持不可被检索、访问的状态，或对其进行匿名化处理。账号注销后，您将无法再以此账号登录和使用所有产品与服务以及产品及服务中与第三方合作的服务内容。 我们在此善意地提醒您，您注销账户的行为会给您向我们或平台上的第三方主张权利带来诸多不便。账号一旦注销完成，将无法恢复，请您在注销前慎重考虑。
                            </div>
                            <div class="pb60">
                                为账号安全考虑，对于账号注销申请，我们可能会对申请人进行身份验证，验证方式以页面显示为准。
                            </div>
                            <div class="logoHeader pb60">
                                您勾选本《注销协议》并点击下一步操作的，即视为您已经签署本《注销协议》并同意遵守其全部内容。
                            </div>
                        </li>
                        <li>
                            <div class="pb60">（如您确定准备注销账号的，请继续向下阅读）</div>
                            <div class="logoHeader">
                                一、为保障您的账号安全和财产权益，在您申请注销账号前，您应当确保该账号为正常状态的账号，否则您可能无法注销该账号。“正常状态”是指该账号应同时符合以下条件：
                            </div>
                            <div class="pb60">
                                <div>- 该账号是您本人通过我们提供的官方渠道注册并由您本人使用的账号；</div>
                                <div>- 该账号内不存在已提供服务但未支付的订单；</div>
                                <div>- 该账号不存在任何未处理完毕的投诉、争议或纠纷，包括但不限于被冻结后未解冻或恢复、投诉举报他人或被他人投诉举报以及升级认证未完成的；</div>
                                <div>- 该账号最近三个月内无任何被检测到异常登录或其他不安全、异常行为的记录；</div>
                                <div>- 该账号无任何未处理完毕的违规被限制、冻结或其他处罚的记录。</div>
                            </div>
                            <div class="pb60">
                                在您提交注销申请后，我们发现或被告知该账号涉及争议、纠纷等，包括但不限于投诉、举报、诉讼、仲裁、国家权力机关、监管部门调查等，我们有权暂停处理您的注销申请并且无需另行得到您的同意。
                            </div>
                        </li>
                        <li class="pb60">
                            <div class="logoHeader">二、您知悉并同意账号注销后，将自行承担下述后果：</div>
                            <div>1.您将无法登录该账号，无法再通过此账号使用相关产品与服务。</div>
                            <div>2.您将无法恢复和访问该账号中的全部数据信息，包括但不限于用户名/昵称、头像、附件简历、在线简历、系统消息、发布的职位、站内信息、历史订单记录、使用记录等。 </div>
                            <div>3.您将无法找回该账号曾登录的PC端、手机App端的所有记录。</div>
                            <div>4.一旦您注销账号，您在账号使用期间获得的已产生但未消耗完毕的增值服务或付费权益及未来的预期利益等视为您已自行放弃，将无法继续使用。我们将会对该账号下的全部权益进行清除处理，包括但不限于账号余额、未消耗的付费职位额度、虚拟道具、红包、卡券、VIP账号权益等各类付费权益（以下合称“剩余资产”）。当您申请注销时，若账户存在剩余资产，我们会作出提示，以便您能够按照平台提示合理处分您的剩余资产。</div>
                            <div class="pb60">5.该账号一旦注销，将无法恢复。您在完成注销后，即使以相同的手机号码或身份证号码再次注册账号并审核通过的，此时该账号将默认为新的用户账号。</div>
                            <div class="important-font">再次向您进行善意提醒，账号一旦注销后将无法恢复，建议您在操作之前自行备份与账号相关的所有信息和数据。</div>
                        </li>
                        <li>
                            <div class="logoHeader">三、账号注销流程</div>
                            <div>1.您需按照我们官方渠道提供的注销流程和操作指引，在相关功能设置页面进行注销操作，结合您的真实意愿选择删除账号的范围；</div>
                            <div>2.您已经仔细阅读与充分理解并同意本《注销协议》的全部内容；同时，您已经检查与确认您申请注销的账号已满足本协议第一条的要求；</div>
                            <div>3.在您完成上述第2点后，我们将根据您申请注销的账号的安全状态以及您的相关产品与服务的使用情况等综合判断您的账号是否符合注销条件；</div>
                            <div>4.如满足注销条件，为保障您的账号和财产权益的安全，我们会根据需要进行注销申请的身份验证（如注册手机验证方式、电话核实身份等），以确保您是申请注销账号的实际使用权人；</div>
                            <div>5.如我们在综合判断后发现您申请注销的账号不符合注销条件的，或者“身份验证”失败的，您的注销可能会失败。 您可以按照我们的注销流程进行后续操作或联系客服协助处理；</div>
                            <div>6.在您完成前述“身份验证”后，视为您已成功向我们提交了账号注销申请。在此期间内，我们为您申请注销的账号提供15个自然日的冷静期（即“冻结期”）。在该冻结期内，您无法登录与使用相关账号，但您的账号所产生的信息暂时不会作物理性删除或匿名化处理。冻结期内，您在提供相关身份信息完成验证后，可通过客服或翻腕平台公布的其他方式申请恢复该账号；</div>
                            <div>7.如冻结期届满，我们未收到您恢复账号的请求或者未出现任何其他不得注销事由的，您申请注销的账号将被永久注销。（具体的注销流程化和操作以我们在官方渠道公布或产品页面提示的为准。）</div>
                            <div>8.您理解并同意，在您申请注销账号期间，如我们需要对相关交易、投诉或其他与该账号相关的事项进行核实的，或者有其他合理理由的，我们有权冻结您申请注销的账号且暂时不为您提供注销服务。</div>
                            <div>9.本协议如有未尽事宜，请参考翻腕《用户协议》或其他相关页面规则、说明、提示等。</div>
                            <div>10.若您在注销过程中有任何问题，您无法处理的或在处理过程中有任何疑惑的，可联系我们的客服协助处理。</div>
                            <div class="important-font">11.您知悉并同意：</div>
                            <div class="important-font">您注销账号的行为，并不能当然地减轻或免除您应承担的法定或约定的义务或责任。</div>
                            <div class="important-font">在您的账号完成注销后，我们有权拒绝响应您提出的与前述相关的任何需求。</div>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
})